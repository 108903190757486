<template>
  <div class='type'>
    <p>单位类别设备分布图</p>
    <div id="typepie">

    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      equipmentList: [
      ],
      totalSum: 30
    };
  },
  created () {

  },
  mounted () {
    this.typePie()
  },
  methods: {
    init (params) {
      var temp = {};
      for (var i in params) {
        var key = params[i].sceneId; //判断依据
        if (temp[key]) {
          temp[key].sceneName = params[i].sceneName;
          temp[key].sceneId = params[i].sceneId;
          temp[key].siteQuantity += params[i].cameraQuantity;//相加值
        } else {
          temp[key] = {};
          temp[key].sceneName = params[i].sceneName;
          temp[key].sceneId = params[i].sceneId;
          temp[key].siteQuantity = params[i].cameraQuantity;
        }
      }
      var newArry = [];
      for (var k in temp) {
        newArry.push(temp[k]);
      }
      // return newArry;
      // console.log(newArry, 111)
      this.equipmentList = newArry
      this.typePie()
    },
    typePie () {
      const chart = this.$echarts.init(document.getElementById('typepie'))
      const data = []
      const color = ['rgba(	139,69,19,0.6)', 'rgba(64,224,208,0.6)', 'rgba(		189,183,107,0.6)', 'rgba(70,130,180,0.6)', 'rgba(240,128,128,0.6)', 'rgba(	0,0,128,0.6)']
      // console.log(this.equipmentList, 22222)
      let totleSum = 0
      this.equipmentList.map((el, index) => {
        data.push({
          value: el.siteQuantity,
          name: el.sceneName,
          itemStyle: {
            normal: {
              color: color[index]
            }
          }
        })
        totleSum += el.siteQuantity
      })
      // 绘制图表
      let option = {
        tooltip: {
          trigger: 'item'
        },
        title: {
          text: totleSum,
          x: 'center',
          y: 'center',
          textStyle: {
            fontSize: '36',
            fontWeight: '400',
            fontFamily: 'Source Han Sans CN',
            color: '#A9A9A9',
          },
        },
        legend: {
          bottom: '4%',
          left: 'center',
          textStyle: {
            color: 'rgba(255, 252, 252, 1)'
          }
        },
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: ['45%', '55%'],
            avoidLabelOverlap: false,
            itemStyle: {
              // borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 1
            },
            labelLine: {
              lineStyle: {
                width: 3
              }
            },
            data: data
          }
        ]
      }
      // console.log(chart, option)
      option && chart.setOption(option)
      window.addEventListener("resize", function () {
        chart.resize();
      })
    }
  }
};
</script>

<style scoped lang="scss">
.type {
  height: 67vh;
  width: 100%;
  background: url("../../../assets/sta/bottom_bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 1px 0 0 30px;
  > p {
    margin-top: 25px;
    font-size: 20px;
    color: #30c6fe;
  }
  #typepie {
    height: 90%;
    // margin: 20px 0;
  }
}
</style>
