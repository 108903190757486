<template>
  <div class='device'>
    <p>当前在线设备/设备总数 <span>{{onlineSum}}/{{totalSum}}</span></p>
    <div id="pie">
    </div>
    <el-row class="titlerow">
      <el-col :span="18">单位名称</el-col>
      <el-col :span="6">在线设备</el-col>
    </el-row>
    <div
      class="swiper"
      v-if="deviceList.length>0"
    >
      <div class="swiper-wrapper">
        <el-row
          class="contentrow swiper-slide"
          v-for="(item,index) in deviceList"
          :key="index"
        >
          <el-col :span="18">{{item.sitename}}</el-col>
          <el-col :span="6">{{item.onlineCameraQuantity}}</el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/swiper.scss';
import Swiper, { Autoplay, Pagination } from 'swiper';
import { onlineList } from '@/api'
Swiper.use([Autoplay, Pagination])
export default {
  data () {
    return {
      deviceList: [],
      onlineSum: 0,
      totalSum: 0
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  methods: {
    // 获取数据
    init () {
      onlineList().then(res => {
        // console.log(res)
        const { data } = res.data
        this.deviceList = data.siteVOS
        this.onlineSum = data.onlineSum
        this.totalSum = data.totalSum
        this.$nextTick(() => {
          this.initSwiper()
          this.drawpie()
        })

      })
    },
    _pie () {
      let dataArr = [];
      for (var i = 0; i < 100; i++) {
        if (i % 2 === 0) {
          dataArr.push({
            value: 10,
            itemStyle: {
              normal: {
                color: 'rgba(25, 38, 63, 1)',
              },
            },
          });
        } else {
          dataArr.push({
            value: 10,
            itemStyle: {
              normal: {
                color: 'rgba(0,0,0,0)',
              },
            },
          });
        }
      }
      return dataArr;
    },
    // 在线设备图
    drawpie () {
      // const _this = this
      const chart = this.$echarts.init(document.getElementById('pie'))
      const percent = (this.onlineSum / this.totalSum * 100).toFixed(2)
      // console.log(percent)
      // 绘制图表
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: function () {
            return `
            <div class="tooltip">
            <i></i>
              <p>
                在线率：
                <span> ${percent} %</span>
              </p>
            </div>
              `
          }
        },
        title: {
          text: percent + '%',
          x: 'center',
          y: 'center',
          textStyle: {
            // fontSize: 36,
            fontWeight: '400',
            fontFamily: 'Source Han Sans CN',
            color: '#A9A9A9',
          },
        },
        series: [
          {
            type: 'liquidFill',
            radius: `${percent}%`,
            center: ['50%', '50%'],
            amplitude: 10,
            data: [
              {
                value: percent / 100,
                // direction: 'left',
                itemStyle: {
                  normal: {
                    color: 'rgba(	100,149,237, 0.4)'
                  }
                }
              },
              {
                value: percent / 100,
                // direction: 'left',
                itemStyle: {
                  normal: {
                    color: 'rgba(		65,105,225, 0.4)'
                  }
                }
              },
              {
                value: percent / 100 - 0.1,
                // direction: 'left',
                itemStyle: {
                  normal: {
                    color: 'rgba(	0,0,128, 0.4)'
                  }
                }
              }
            ],
            backgroundStyle: {
              borderWidth: 1,
              color: 'rgba(27, 192, 179, 0.06)',
            },
            label: {
              normal: {
                formatter: '',
              },
            },
            outline: {
              show: false,
            },
          },
          {
            type: 'pie',
            clockWise: true,
            hoverAnimation: false,
            radius: ['88%', '92%'],
            center: ['50%', '50%'],
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
            },
            data: [
              {
                value: 100,
                itemStyle: {
                  normal: {
                    color: new this.$echarts.graphic.LinearGradient(0.2, 1, 0.3, 0, [
                      {
                        offset: 0,
                        color: 'rgba(65,105,225, 0.8)',
                      },
                      {
                        offset: 1,
                        color: 'rgba(0,0,128, 0.8)',
                      },
                    ]),
                  },
                },
              },
            ],
          },
          {
            type: 'pie',
            silent: true,
            hoverAnimation: false,
            radius: ['95%', '99%'],
            center: ['50%', '50%'],
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
              },
            },
            data: this._pie(),
          },
        ],
      }
      // console.log(chart, option)
      option && chart.setOption(option)
      window.addEventListener("resize", function () {
        chart.resize();
      })
    },
    initSwiper () {

      if (this.deviceList && this.deviceList.length > 11) {
        new Swiper('.swiper', {
          autoplay: {
            delay: 0,
            stopOnLastSlide: false,
            disableOnInteraction: false,
            // pauseOnMouseEnter: true
          },
          direction: 'vertical',
          slidesPerView: 'auto',
          loop: true,
          speed: 1500,
          observer: true,
          observeParents: true
        })
      }
      else if (this.deviceList && this.deviceList.length <= 11) {
        new Swiper('.swiper', {
          direction: 'vertical',
          slidesPerView: 'auto',
          speed: 1500
        })
      }
      // })
    }
  }
};
</script>

<style scoped lang="scss">
.device {
  height: 67vh;
  // width: 100%;
  background: url("../../../assets/sta/bottom_bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 1px 30px;
  > p {
    margin-top: 25px;
    font-size: 20px;
    color: #30c6fe;
    span {
      font-size: 18px;
      color: #30c6fe;
    }
  }
  #pie {
    height: 30%;
    margin: 20px 0;
  }
  .titlerow {
    background: #083972;
    padding: 5px 10px;
    .el-col {
      color: #30c6fe;
      font-size: 14px;
      font-weight: bold;
    }
    .el-col-6 {
      text-align: center;
    }
  }
  .swiper {
    margin-top: 5px;
    height: 42%;
    overflow: hidden;
    padding: 0 10px;
    .swiper-slide {
      height: 25px;
      color: #30c6fe;
      font-size: 14px;
      .el-col-6 {
        color: #30c6fe;
        text-align: center;
      }
      .el-col-18 {
        color: #30c6fe;
        // text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
