<template>
  <div class='statistics'>
    <div class="typerow">
      <div v-for="item in listSum" :key="item.id" :style="{width:`${100/listSum.length}%`}">
        <el-popover width="300" trigger="hover" placement="right" popper-class="hotel-popover" :visible-arrow="false">
          <!-- <el-button>hover 激活</el-button> -->
          <div slot="reference" class="item" :id="item.classname" @click="changeNav(item)" :style="{backgroundImage:item.id === navNum?`url(${item.hover})`:`url(${item.bg})`}">
            <p><img :src="item.icon" alt=""></p>
            <div>
              <h4>{{item.siteQuantity?item.siteQuantity:0}}</h4>
              <p>{{item.sceneName}}</p>
            </div>
          </div>
          <vue-scroll :ops="ops">
            <div v-for="(hotel,index) in classifyList" :key="index" class="hotelname">
              <ul v-if="hotel.sceneName === item.sceneName">
                <li v-for="el in hotel.data" :key="el.id">{{el.sitename}}</li>
              </ul>
            </div>
          </vue-scroll>
        </el-popover>
      </div>
    </div>
    <el-row :gutter="40" class="contentrow">
      <el-col :span="6">
        <device-chart />
      </el-col>
      <el-col :span="12">
        <div id="map"></div>
      </el-col>
      <el-col :span="6">
        <TypeChart ref="typechart" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import DeviceChart from './components/deviceChart'
import TypeChart from './components/typeChart'
import AMap from 'AMap'
import { listSceneSiteSum, sitelist } from '../../api'
export default {
  components: { DeviceChart, TypeChart },
  data () {
    return {
      navList: [
        { id: 0, name: '酒店', icon: require('../../assets/sta/hotel.png'), classname: 'hotel', bg: require('../../assets/sta/hotel_bg.png'), hover: require('../../assets/sta/hotel_hover.png') },
        { id: 1, name: '影院', icon: require('../../assets/sta/cinema.png'), classname: 'cinema', bg: require('../../assets/sta/cinema_bg.png'), hover: require('../../assets/sta/cinema_hover.png') },
        { id: 2, name: '医院', icon: require('../../assets/sta/hospital.png'), classname: 'hospital', bg: require('../../assets/sta/hospital_bg.png'), hover: require('../../assets/sta/hospital_hover.png') },
        { id: 3, name: '学校', icon: require('../../assets/sta/school.png'), classname: 'school', bg: require('../../assets/sta/school_bg.png'), hover: require('../../assets/sta/school_hover.png') },
        { id: 4, name: '辐射', icon: require('../../assets/sta/radiation.png'), classname: 'radiation', bg: require('../../assets/sta/radiation_bg.png'), hover: require('../../assets/sta/radiation_hover.png') },
        { id: 5, name: '口腔', icon: require('../../assets/sta/tooth.png'), classname: 'tooth', bg: require('../../assets/sta/tooth_bg.png'), hover: require('../../assets/sta/tooth_hover.png') }
      ],
      listSum: [],
      navNum: null,
      map: null,
      // 地图标记点
      markers: [],
      // 网点列表
      unitList: [],
      // 各分类列表数量
      classifyList: [],
      // 滚动条参数
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: '#999999', //滚动条颜色
          opacity: 0.6, //滚动条透明度
          'overflow-x': 'hidden',
          minSize: 0.1
        },
        videoData: [],
      },
    };
  },
  created () {
    this.init()
  },
  mounted () {
    // this.initMap()
  },
  methods: {
    // 获取数据
    init () {
      listSceneSiteSum().then(res => {
        const arrs = res.data.data.map((el, index) => {
          return {
            ...el,
            ...this.navList[index]
          }
        })
        // const arrs = this.navList.map(item => {
        //   const data = res.data.data.find(i => item.name == i.sceneName)
        //   return {
        //     ...item,
        //     ...data,
        //   }
        // })
        this.listSum = arrs
        // console.log('arrs :>> ', arrs);
      })
      sitelist().then(res => {
        // console.log(res, 222)
        this.unitList = res.data.data
        this.classifyList = this.dealwithArr(this.unitList)
        this.$refs.typechart.init(res.data.data)
        this.initMap()
      })
    },
    dealwithArr (arr) {
      let map = {};
      let myArr = [];
      for (let i = 0; i < arr.length; i++) {
        if (!map[arr[i].sceneName]) {
          myArr.push({
            sceneName: arr[i].sceneName,
            data: [arr[i]]
          });
          map[arr[i].sceneName] = arr[i]
        } else {
          for (let j = 0; j < myArr.length; j++) {
            if (arr[i].sceneName === myArr[j].sceneName) {
              myArr[j].data.push(arr[i]);
              break
            }
          }
        }
      }
      return myArr
    },
    changeNav (item) {
      this.navNum = item.id
      document.getElementById(item.classname).style.backgroundImage = `url(${item.hover})`
      //    const map = this.state.map
      // const markers = this.state.markers
      this.map.remove(this.markers)
      this.markers = []
      const arr = this.unitList.filter(el => el.sceneName === item.sceneName)
      console.log('arr', arr)
      arr.map(el => {
        const marker = new AMap.Marker({
          position: new AMap.LngLat(el.longitude, el.latitude || 27.61842),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: el.sitename
        })
        this.markers.push(marker)
        this.map.add(marker)
      })
      // console.log(this.markers)
      this.map.setFitView(this.markers)

    },
    initMap () {
      // let arr = this.state.maplist
      this.map = new AMap.Map("map", {
        // mapStyle: 'amap://styles/bf1ea8bba3f1078dc72353a105738e82',
        center: [119.06256, 27.61842],//地图中心点
        zoom: 15,//地图显示的缩放级别
        viewMode: '3D',
        pitch: 30,
      });
      // 添加标记点
      this.unitList.map(el => {
        const marker = new AMap.Marker({
          position: new AMap.LngLat(el.longitude, el.latitude || 27.61842),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: el.sitename
        })
        this.markers.push(marker)
        this.map.add(marker)
      })
      this.map.setFitView(this.markers)
    },
    showHotelName (val) {
      console.log(val)
    }
  }
};
</script>

<style scoped lang="scss">
.statistics {
  .typerow {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    > div {
      // width: 90%;
      .item {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 140px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        transition: all 1s;
        > p {
          width: 25%;
          img {
            width: 100%;
          }
        }
        > div {
          text-align: center;
          color: white;
          h4 {
            font-size: 36px;
          }
          p {
            font-size: 16px;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .contentrow {
    margin-top: 20px;
    width: 100%;
  }
  #map {
    width: 100%;
    height: 67vh;
  }
}
</style>
